import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OtherDetails, UpdateOtherDetails } from '../model';
import { WealthService } from './wealth.service';

@Injectable({
  providedIn: 'root',
})
export class WealthOtherService {
  private basePath = 'other';

  constructor(private wealthService: WealthService) {}

  public getOtherById(id: number): Observable<OtherDetails> {
    return this.wealthService.getEntityById<OtherDetails>(this.basePath, id);
  }

  public updateOtherById(id: number, data: UpdateOtherDetails): Observable<OtherDetails> {
    return this.wealthService.updateEntityById<OtherDetails, UpdateOtherDetails>(this.basePath, id, data);
  }

  public deleteOtherById(id: number): Observable<any> {
    return this.wealthService.deleteEntityById(this.basePath, id);
  }

  public uploadImageToOtherUrl(url: string, file: File, id: number): Observable<any> {
    return this.wealthService.uploadImageToEntityUrl(url, file, id, this.basePath);
  }
}
