import { Advisor } from './advisor';
import { MandateApproval } from './broker-mandate-approval.model';

export class Person {
  uid: string;
  email: string = '';
  birthday: Date | string;
  firstname: string = '';
  lastname: string = '';
  profilePictureUrl: string = '';
  taxId: string = '';
  street: string = '';
  houseNumber: string = '';
  zip: number;
  city: string = '';
  mobile: string = '';
  connectedAccounts: number;
  hasProviderConsentsAccounts: boolean;
  firstLogin: boolean;
  advisor: Advisor;
  mandateApproval: MandateApproval;
  hasPoolMandate?: boolean;
  showRating: boolean;
  poolMandate: boolean;
}

export class BankConnectionIBANInfo {
  id: number;
  bankName: string;
  iban: string;
  bic: string;
}
