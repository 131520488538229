import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { RealEstateDetails, WealthRealEstateUpdate } from '../model';
import { WealthService } from './wealth.service';

@Injectable({
  providedIn: 'root',
})
export class WealthRealEstateService {
  private basePath = 'real-estate';

  constructor(private wealthService: WealthService) {}

  public getRealEstateById(id: number): Observable<RealEstateDetails> {
    return this.wealthService.getEntityById<RealEstateDetails>(this.basePath, id);
  }

  public updateRealEstateById(id: number, realEstate: WealthRealEstateUpdate): Observable<RealEstateDetails> {
    return this.wealthService.updateEntityById<RealEstateDetails, WealthRealEstateUpdate>(
      this.basePath,
      id,
      realEstate,
    );
  }

  public deleteRealEstateById(id: number): Observable<void> {
    return this.wealthService.deleteEntityById(this.basePath, id);
  }

  public uploadImageToRealEstateUrl(url: string, file: File, id: number): Observable<any> {
    return this.wealthService.uploadImageToEntityUrl(url, file, id, this.basePath);
  }
}
