import { Injectable } from '@angular/core';
import { ArtDetails, UpdateArtDetails } from '../model';
import { Observable } from 'rxjs';
import { WealthService } from './wealth.service';

@Injectable({
  providedIn: 'root',
})
export class WealthArtService {
  private basePath = 'art';

  constructor(private wealthService: WealthService) {}

  public getArtById(id: number): Observable<ArtDetails> {
    return this.wealthService.getEntityById<ArtDetails>(this.basePath, id);
  }

  public updateArtById(id: number, data: UpdateArtDetails): Observable<ArtDetails> {
    return this.wealthService.updateEntityById<ArtDetails, UpdateArtDetails>(this.basePath, id, data);
  }

  public deleteArtById(id: number): Observable<any> {
    return this.wealthService.deleteEntityById(this.basePath, id);
  }

  public uploadImageToArtUrl(url: string, file: File, id: number): Observable<any> {
    return this.wealthService.uploadImageToEntityUrl(url, file, id, this.basePath);
  }
}
