import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, switchMap, tap, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { WealthAttachments } from '../model';
import WithSubscription from '../utils/WithSubscriptions';

@Injectable({
  providedIn: 'root',
})
export class WealthService extends WithSubscription(class {}) {
  private apiEndpoint = `${environment.apiEndpoint}/wealth`;

  private attachmentsSubject = new BehaviorSubject<any[]>([]);
  public attachments$ = this.attachmentsSubject.asObservable();
  public filteredImages$ = new BehaviorSubject<{ attachmentUrl: string; id: string }[]>([]);

  public loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();

  constructor(private http: HttpClient) {
    super();
  }

  public getAggregatedAssets(userId: string): Observable<any> {
    return this.http.get<any>(`${this.apiEndpoint}/aggregated-assets/${userId}`).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error) => {
        console.error('Error fetching aggregated assets:', error);
        return error;
      }),
    );
  }

  public saveWealthData(wealthType: string, data: any): Observable<any> {
    const endpoint = this.getEndpoint(wealthType);
    console.log('data', data);
    const dataToSend = {
      ...data,
      numberOfImageToAdd: data.numberOfImageToAdd.length,
    };
    return this.http.post<any>(endpoint, dataToSend).pipe(
      map((res: any) => res),
      catchError((error) => {
        console.error('Error saving wealth data:', error);
        return throwError(() => new Error('Failed to save wealth data'));
      }),
    );
  }

  private getEndpoint(wealthType: string): string {
    const endpoints = {
      'real-estate': `${this.apiEndpoint}/real-estate`,
      vehicle: `${this.apiEndpoint}/vehicle`,
      jewelry: `${this.apiEndpoint}/jewelry`,
      'precious-metal': `${this.apiEndpoint}/precious-metal`,
      art: `${this.apiEndpoint}/art`,
      other: `${this.apiEndpoint}/other`,
      // Add more wealth types and their corresponding endpoints here
    };

    const endpoint = endpoints[wealthType];
    if (!endpoint) {
      throw new Error(`Unknown wealth type: ${wealthType}`);
    }

    return endpoint;
  }

  public getEntityById<T>(basePath: string, id: number): Observable<T> {
    return this.http
      .get<T>(`${this.apiEndpoint}/${basePath}/${id}`)
      .pipe(tap((res: any) => this.updateAttachments(res.attachments)));
  }

  public updateEntityById<T, U>(basePath: string, id: number, data: U): Observable<T> {
    return this.http
      .put<T>(`${this.apiEndpoint}/${basePath}/${id}`, data)
      .pipe(tap((res: any) => this.updateAttachments(res.attachments)));
  }

  public deleteEntityById(basePath: string, id: number): Observable<void> {
    return this.http.delete<void>(`${this.apiEndpoint}/${basePath}/${id}`);
  }

  public uploadImageToEntityUrl(url: string, file: File, id: number, basePath: string): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': file.type,
      'x-amz-acl': 'private',
    });

    return this.http.put(url, file, { headers, observe: 'response', responseType: 'text' }).pipe(
      switchMap(() => this.getEntityById(basePath, id)),
      tap((res: any) => this.updateAttachments(res.attachments)),
    );
  }

  public updateAttachments(attachments: WealthAttachments[]): void {
    const allAttachments = attachments.map((attachment) => ({
      id: attachment.id,
      mainAsset: attachment.mainAsset,
      attachments: attachment.attachments,
    }));
    this.attachmentsSubject.next(allAttachments);
  }

  public filterImagesByType(type: 'SMALL' | 'MEDIUM' | 'FULL'): void {
    this.loadingSubject.next(true);

    this.listenToSubject(
      this.attachments$.pipe(
        map((attachments: any[]) =>
          attachments
            .map((attachment) => {
              let downloadUrl = '';

              if (type === 'SMALL') {
                downloadUrl =
                  attachment.attachments?.thumbnailSmall?.downloadUrl || attachment.mainAsset?.downloadUrl || '';
              } else if (type === 'MEDIUM') {
                downloadUrl =
                  attachment.attachments?.thumbnailMedium?.downloadUrl || attachment.mainAsset?.downloadUrl || '';
              } else if (type === 'FULL') {
                downloadUrl = attachment.mainAsset?.downloadUrl || '';
              }

              return {
                attachmentUrl: downloadUrl,
                id: attachment.id.toString(),
              };
            })
            .filter((image) => !!image.attachmentUrl),
        ),
      ),
      (filteredImages) => {
        this.filteredImages$.next(filteredImages);
        // Ladezustand deaktivieren
        this.loadingSubject.next(false);
      },
    );
  }
}
