<div class="mobile-user-sidebar-container" [@sideMenu]="">
  <!-- User name and picture -->
  <div class="content-header content-header-fullrow">
    <div class="content-header-section align-parent">
      <button type="button" class="btn btn-circle btn-dual-secondary align-v-r" (click)="closeActiveMenu()">
        <i class="bi bi-arrow-right theme-color"></i>
      </button>

      <div class="content-header-item d-flex">
        <span class="mr-5">
          <button
            (click)="navigateToOtherPage('/settings')"
            class="btn btn-circle d-flex align-items-center justify-content-center no-border"
          >
            @if (advisorLoading) {
              <ngx-skeleton-loader
                appearance="circle"
                [theme]="{
                  width: '30px',
                  height: '30px',
                  margin: '0'
                }"
              ></ngx-skeleton-loader>
            } @else {
              @if (person?.profilePictureUrl) {
                <img class="img-avatar32 user-img-icon" src="{{ person.profilePictureUrl }}" alt="user image" />
              } @else {
                <img
                  class="img-avatar32 user-img-icon"
                  src="https://static-assets.vinlivt.de/img/misc/bird.png"
                  alt="user image"
                />
              }
            }
          </button>
        </span>

        <span class="ml-2 align-middle link-effect theme-color font-w600 ellipsis" href="be_pages_generic_profile.html">
          {{ person.firstname + ' ' + person.lastname }}
        </span>
      </div>
    </div>
  </div>

  <!-- Contacts -->
  <div class="pull-r-l">
    <div class="block-header bg-body-light">
      <h3 class="block-title">Kontakt</h3>
    </div>
    <div class="content-side theme-color menu-item">
      <a class="dropdown-item theme-color" href="javascript:void(0)" (click)="openAdvisorCard()">
        <i class="bi bi-shield-check side-bar-icon"></i>
        <span>Beratung</span>
      </a>

      <a
        class="dropdown-item theme-color pointer-cursor"
        (click)="goToOtherExternalUrl('https://1jd89nuf213.typeform.com/to/n75PL635')"
      >
        <i class="bi bi-send side-bar-icon"></i>
        <span>Support</span>
        <span class="badge badge-primary">{{ person.connectedAccounts }}</span>
      </a>

      <a
        class="dropdown-item theme-color"
        href="javascript:void(0)"
        (click)="goToOtherExternalUrl('https://vinlivtgmbh-assist.freshdesk.com/support/solutions')"
      >
        <i class="bi bi-question-diamond side-bar-icon"></i>
        <span>FAQ</span>
      </a>
      <!--      <a class="dropdown-item" (click)="goToOtherExternalUrl('https://www.vinlivt.de/vinletter-vorsorge-tipps/')">-->
      <!--        <img src="assets/images/icon/line-icons/email-new.png" class="menu-icon" alt="icon" /> Vinletter Anmeldung-->
      <!--      </a>-->
    </div>
  </div>

  <!-- Friends -->
  <div class="pull-r-l">
    <div class="block-header bg-body-light">
      <h3 class="block-title">Profil</h3>
    </div>
    <div class="content-side menu-item">
      <a
        class="dropdown-item theme-color"
        id="person-profile"
        href="javascript:void(0)"
        (click)="navigateToOtherPage('/person-profile')"
      >
        <i class="bi bi-clipboard-check side-bar-icon"></i>
        <span>Finanzprofil Daten</span>
      </a>

      <a class="dropdown-item theme-color" href="javascript:void(0)" (click)="navigateToOtherPage('/settings')">
        <i class="bi bi-person side-bar-icon"></i>
        <span>Persönliche Daten</span>
      </a>

      <a class="dropdown-item theme-color" href="javascript:void(0)" (click)="navigateToOtherPage('/notifications')">
        <i class="bi bi-bell side-bar-icon"></i>
        <span>Benachrichtigungen</span>
      </a>

      <a class="dropdown-item theme-color" href="javascript:void(0)" (click)="openThemeModal()">
        <i class="bi bi-star-half side-bar-icon"></i>
        <span>Designmodus</span>
      </a>

      <a
        class="dropdown-item theme-color"
        href="javascript:void(0)"
        id="confidential-access-link"
        (click)="navigateToOtherPage('/confidential-access')"
      >
        <i class="bi bi-sign-stop-lights side-bar-icon"></i>
        <span>Privatsph&#228;re</span>
      </a>

      <a
        class="dropdown-item theme-color"
        href="javascript:void(0)"
        (click)="navigateToOtherPage('/bank-accounts-overview')"
      >
        <i class="bi bi-arrow-left-right side-bar-icon"></i>
        <span>Bankzugänge</span>
        <span class="badge badge-primary">{{ person.connectedAccounts }}</span>
      </a>
    </div>
  </div>

  <!-- Security -->
  <div class="pull-r-l">
    <div class="block-header bg-body-light">
      <h3 class="block-title">Schutz</h3>
    </div>
    <div class="content-side menu-item">
      <a
        class="dropdown-item theme-color card-head d-flex justify-content-between align-items-center"
        id="datenschutz-link"
        data-toggle="collapse"
        data-target="#collapseConsents"
        aria-expanded="false"
        aria-controls="collapseConsents"
        href="javascript:void(0)"
      >
        <div>
          <i class="bi bi-key side-bar-icon"></i>
          <span>Datenschutz</span>
        </div>

        <span class="d-flex align-items-center justify-content-center"
          ><i class="fa fa-angle-down rotate-icon"></i
        ></span>
      </a>

      <div id="collapseConsents" class="collapse" aria-labelledby="consents">
        <a class="dropdown-item theme-color" href="javascript:void(0)" (click)="authService.updatePassword()">
          <i class="bi bi-lock side-bar-icon"></i>
          <span>Passwort &#228;ndern</span>
        </a>

        <a
          class="dropdown-item theme-color"
          href="javascript:void(0)"
          (click)="consentForCompany(consentType.PARTNER_PRIVACY_CLIENT)"
        >
          <i class="bi bi-info-circle side-bar-icon"></i>
          <span>Datenschutz Partner</span>
        </a>

        <a
          class="dropdown-item theme-color"
          href="javascript:void(0)"
          (click)="consent(consentType.VINLIVT_PRIVACY_CLIENT)"
        >
          <i class="bi bi-info-circle side-bar-icon"></i>
          <span>Datenschutz Vinlivt</span>
        </a>

        <a
          class="dropdown-item theme-color"
          href="javascript:void(0)"
          (click)="consent(consentType.VINLIVT_TERMS_OF_USE_CLIENT)"
        >
          <i class="bi bi-info-circle side-bar-icon"></i>
          <span>App-Nutzung Vinlivt</span>
        </a>
      </div>
    </div>
  </div>

  <!-- Social -->
  <!--  <div class="pull-r-l">-->
  <!--    <div class="block-header bg-body-light">-->
  <!--      <h3 class="block-title">Follow us</h3>-->
  <!--    </div>-->
  <!--    <div class="content-side menu-item">-->
  <!--      <a-->
  <!--        class="dropdown-item"-->
  <!--        href="javascript:void(0)"-->
  <!--        (click)="goToOtherExternalUrl('https://www.facebook.com/people/Vinlivt/100086760733208/')"-->
  <!--      >-->
  <!--        <i class="si si-social-facebook mr-10"></i> Facebook-->
  <!--      </a>-->
  <!--      <a-->
  <!--        class="dropdown-item"-->
  <!--        href="javascript:void(0)"-->
  <!--        (click)="goToOtherExternalUrl('https://www.instagram.com/vinlivt_app/')"-->
  <!--      >-->
  <!--        <i class="si si-social-instagram mr-10"></i> Instagram-->
  <!--      </a>-->
  <!--      <a-->
  <!--        class="dropdown-item"-->
  <!--        href="javascript:void(0)"-->
  <!--        (click)="goToOtherExternalUrl('https://www.linkedin.com/company/vinlivt/')"-->
  <!--      >-->
  <!--        <i class="si si-social-linkedin mr-10"></i> LinkedIn-->
  <!--      </a>-->
  <!--    </div>-->
  <!--  </div>-->

  <!-- Mobile app links -->
  @if (isDev && !isNative) {
    <div class="pull-r-l">
      <div class="block-header bg-body-light">
        <h3 class="block-title">Hol dir die App</h3>
      </div>
      <div class="content-side menu-item mb-20 mt-20">
        <div class="d-flex flex-column align-items-center">
          <img
            class="mb-10 pointer-cursor"
            src="https://static-assets.vinlivt.de/icons/misc/download-app-store.svg"
            alt="apple store"
            width="110"
          />

          <img
            class="mt-10 pointer-cursor"
            src="https://static-assets.vinlivt.de/icons/misc/download-play-store.svg"
            alt="play store"
            width="110"
          />
        </div>
      </div>
    </div>
  }

  <div class="pull-r-l mt-30 pb-30">
    <div class="px-20">
      <button
        type="button"
        class="btn btn-rounded btn-block purple-outline-btn mt-50"
        [disabled]="logoutSpinner"
        (click)="logout()"
      >
        <span>Logout</span>

        @if (logoutSpinner) {
          <div class="spinner-border text-purple ml-2" role="status" style="width: 15px; height: 15px">
            <span class="sr-only">Loading...</span>
          </div>
        }
      </button>
    </div>
  </div>
</div>
